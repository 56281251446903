//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Menu = styled.div `
    position: absolute;
    top: ${props => props.positionTop ? props.positionTop+"px" : "0"};
    right: ${props => props.positionRight ? props.positionRight+"px" : "0"};
    background-color: #FFFFFF;
    width: 290px;
    z-index: 9999;
    padding: 0.3125rem 0.9375rem;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    -webkit-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);

    &::before {
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #F1F1F1;
        position: absolute;
        top: -12px;
        right: 60px;
    }
    &::after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #FFFFFF;
        position: absolute;
        top: -11px;
        right: 60px;
    }
`;