//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React from "react";
import styled, { css } from "styled-components";

//===============================================================================================================
// #2 - Import * Services
//===============================================================================================================
import EventEmitter from "../../../../../services/Events";

//===============================================================================================================
// #4 - Import * Icons
//===============================================================================================================
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

//===============================================================================================================
// #8 - Import * Material UI Components
//===============================================================================================================
import { Dialog, DialogContent, DialogTitle, useMediaQuery, Typography, Slide} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

//===============================================================================================================
// #4 - Import * UI Components
//===============================================================================================================
import { ButtonLabel } from "../../../../../components/design/form/button";

const styles = {
    position: "absolute",
    top: "15px",
    left: "15px",
    zIndex: 2
}

//===============================================================================================================
// #2 - Instantiated Services
//===============================================================================================================
export const CategoryMenuDialog = React.forwardRef((props, ref) => {

    // # Define States
    const [open, setOpen] = React.useState(false);

    // * Material UI Styles - useTheme
    const theme = useTheme();

    // # Define Material UI Modal Dialog Fullscreen on mobile
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // # Define Functions

    // * Set Material UI Modal Dialog Open
    const handleClickOpen = () => {
        setOpen(true);
    };

    // * Set Material UI Modal Dialog Close
    const handleClose = () => {
        setOpen(false);

        EventEmitter.dispatch("close-dialog-cat");
    };

    // * Set Function Using useImperativeHandle();
    React.useImperativeHandle(ref, () => ({
        openModal: () => handleClickOpen(),
        closeModal: () => handleClose(),
    }));

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                open={open}
                maxWidth={"sm"}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className="UserMenuDialog CategoriesMenuDiloag"
            >
                <DialogTitle className="DialogTitle" id="responsive-dialog-title">
                    <ButtonLabel style={styles} buttonColor="#F6F6F9" icon={<I icon={["far", "times"]} />} text="Fechar" onClick={handleClose} />
                    <h1 className="title">Categorias</h1>
                </DialogTitle>
                <DialogContent className="ModalContent">
                    <Typography component={"span"} variant={"body2"}>
                        {props.children}
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
});

// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});