//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const ScambPoints = styled.div((props) => {

    const { theme } = props;
    const { color, fontWeight } = Object.assign( {}, theme );

    return `

        margin-right: 0;
        margin-left: 10px;
        margin-top: 4px;
        min-width: 56px;

        .title {
            opacity: 0.8;
        }

        ._product-price._1x {

            .ui-item__price {

                .price-tag-fraction {
                    line-height: 1;
                    font-size: 0.8125rem;
                }

                .price-tag-symbol {
                    top: -2px;
                }
            }
        }
    `
})

export const Title = styled.div `
    font-weight: 700;
    font-size: 0.5rem;
    color: ${props => props.theme.color.black};
    text-align: left;
    opacity: 0.8;
`