//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

//===============================================================================================================
// #3 - Import * Styles
//===============================================================================================================
import { ScambPoints, Title } from "./styles";

//===============================================================================================================
// #4 - Import * UI Components
//===============================================================================================================
import { Points } from "../price-types";

export default function ScamberPoints({title, points, link}) {

    return (
        <ScambPoints className="scamb-points">
        <Link to={link ? link : "#link-undefined"}>
            <Title>
                {title}
            </Title>

            <Points value={points} />

        </Link>
        </ScambPoints>
    )
}