//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #3 - Import * Font Awesome Icons
//===============================================================================================================
import { Menu } from "./styles";


export const Dropdown = ({ children }) => {

    return (
        <Menu className="dropdown" positionTop={41} positionRight={-50}>
            { children }
        </Menu>
    )
}