
import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Menu, MenuBars as Bars, WrapUser, TextButtonUser } from "./styles";

export const MenuBars = () => {

    return (
        <>
            <Menu>
                <Bars />
                <Bars />
                <Bars />
            </Menu>
        </>
    )
}

export const User = ({ buttonLink, children }) => {

    return (
        <WrapUser type="button">
            <Link to={buttonLink}>
                <Icon icon={["fal", "user"]} />
                <TextButtonUser>
                    {children}
                </TextButtonUser>
            </Link>
        </WrapUser>
    )
}