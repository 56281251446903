//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const SearchForm = styled.form((props) => {

    const { theme } = props;
    const { color, media } = Object.assign( {}, theme );

    return `
        border: 1px solid ${color.iceBold};
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.3125rem;
        background-color: ${color.white};
        width: 100%;
        overflow: hidden;
        
        ${media("xs", `
            padding: 0 0.9375rem 0 0;
            flex-direction: row-reverse;
            `)}
            
            ${media("md", `
            padding: 0 0 0 0.9375rem;
            flex-direction: row;
            min-width: 380px;
        `)}
    `
});

export const FormField = styled.input `
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.color.transparent};
    border: 0;
    width: 100%;
    font-size: 14px;
    font-weight: ${props => props.theme.fontWeight.fw700};
    color: ${props => props.theme.color.black};

    &::placeholder {
        font-family: ${props => props.theme.font.nunito};
        font-size: 16px;
        opacity: 0.6;
    }
`;

export const Submit = styled.button`
    border: 0;
    height: 42px;
    width: 48px;
    min-width: 48px;
    background-color: ${props => props.theme.color.transparent};

    .fa-search {
        position: relative;
        top: 2px;
        width: 16px;
        height: 16px;

        path {
            
            fill: ${props => props.theme.color.grayBold};
        }
    }
`;

export const SwitchSearch = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.color.ice};
    border-radius: 100px;
`;

export const SwitchLabel = styled.label `
    
    margin: 0;

    input[type="radio"],
    input[type="checkbox"] {
        display: none !important;
        opacity: 0 !important;
    }

    input:checked ~ .switch-mark {
        background-color: ${props => props.theme.color.blue};
        color: ${props => props.theme.color.white};
        border-radius: 100px;
    }
`;
export const SwitchMark = styled.div `
    
    font-size: 10px;
    font-weight: ${props => props.theme.fontWeight.fw700};
    color: ${props => props.theme.color.gray};
    background-color: transparent;
    padding: 0.1rem 0.5rem;
    cursor: pointer;
`;

