import Service from './Service';

export default class Authentication extends Service {

  constructor(){
    super();
  }

  doLogin( userAndPassword, website ) {
    return this.axiosInstance.post(`/auth/local${website? '?website=' + website:''}`, userAndPassword);
  }

  doFacebookLogin(inviteCode){
    window.location.href = `${this.server}/auth/facebook${inviteCode? '?inviteCode=' + inviteCode: ''}`;
  }

  confirmFacebookLogin( tokenFacebook, website ){
    return this.axiosInstance.get(`/auth/facebook/callback?code=${tokenFacebook}${website? '&website=' + website:''}`);
  }

  isAuthenticated() {
    return this.axiosInstance.get(`/auth/isAuthenticated`);
  }

  doLogout() {
    return this.axiosInstance.get(`/auth/logout`);
  }

  createUserSession() {
    return this.axiosInstance.post(`/auth/session`);
  }

}
