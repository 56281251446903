//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

//===============================================================================================================
// #3 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export const Menu = ({ children }) => {

    return (

        <nav className="scamb-menu">
            <ul>
                {children}
            </ul>
        </nav>
    )
}

export const MenuItem = ({ link, icon, title, subtitle, onClick }) => {

    return (
        <li>
            <Link to={link ? link : "#!"} onClick={onClick}>
                <div className="menu-item-columns">
                    <div className="menu-item-column">
                        <div className="icon">
                            {icon}
                        </div>

                        <div className="menu-description">
                            <div className="title">
                                {title}
                            </div>

                            {subtitle && (
                            <div className="subtitle">
                                {subtitle}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="menu-item-column">
                        <div className="icon">
                            <Icon icon={faChevronRight} />
                        </div>
                    </div>
                </div>
            </Link>
        </li>
    )
}