import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.625rem 0.9375rem;
  background-color: ${({theme}) => theme.color.blue};
  
  a {
      display: block;
  }
`;

export const Text = styled.p`
    text-align: center;
    color: ${({theme}) => theme.color.white};
    font-weight: ${({theme}) => theme.fontWeight.fw700};
    margin-bottom: 0;
    line-height: 1.4;
    
    ${({theme}) => theme.media('xs', `
        font-size: 13px;
    `)}
    
    ${({theme}) => theme.media('md', `
        font-size: 14px;
    `)}

    span {
        font-size: 0.6875rem;
    }
`;