//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Tab = styled.section `
    width: 100%;
    height: 54px;
    background-color: ${props => props.tabColor};
    position: relative;

    &::after {
        content: "";
        display: block;
        height: 3px;
        width: 100%;
        background-color: rgba(255,255,255,0.2);
        position: absolute;
        bottom: 0;
        left: 0;

    }

    section {
        height: 54px;
        
        ._med-container,
        .no-container {
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .column {
                height: 54px;
                display: flex;
                align-items: center;
            
                ${props => props.theme.media("xs", `
                    justify-content: space-between;
                    width: 100%;
                `)}
                
                ${props => props.theme.media("sm", `
                    justify-content: flex-start;
                    width: auto;
                `)}

                .scamb-points {
                    margin-right: 15px;
                    div {
                        color: ${props => props.theme.color.white} !important;
                    }

                }

                .column-item {
                    background-color: transparent;
                    position: relative;
                    cursor: pointer;

                    .dropdown {
                        display: none;

                        .scamb-menu {

                            ul {

                                li {
                                    &:last-child {
                                        a {

                                            .menu-item-columns {
                                                .icon {
                                                    svg {
                                                        path {
                                                            fill: ${props => props.theme.color.red}
                                                        }
                                                    }
                                                }

                                                .menu-description {

                                                    .title {
                                                        color: ${props => props.theme.color.red};
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .dropdown {
                            display: block;
                        }
                    }
                }

                &:nth-child(2) {
                    ${props => props.theme.media("xs", `
                        display: none;
                    `)}

                    ${props => props.theme.media("md", `
                        display: flex;
                    `)}
                }
            }
        }
    }
`;

export const TabButton = styled.button `
    border: 0;
    background-color: ${props => props.active === "active" ? props.activeColor : "transparent"};
    color: ${props => props.theme.color.white };
    height: 100%;
    position: relative;
    opacity: ${props => props.active === "active" ? "1" : "0.6"};

    ${props => props.theme.media("xs", `
        width: 100%;
    `)}

    ${props => props.theme.media("sm", `
        width: auto;
    `)}
    
    &::after {
        display: ${props => props.active === "active" ? "block" : "none"};
        content: "";
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid rgba(255,255,255,0.2);
        position: absolute;
        bottom: 0.1875rem;
        left: 0;
        right: 0;
        margin: auto;
    }
        
    a {
        color: ${props => props.theme.color.white };
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        font-weight: ${props => props.theme.fontWeight.fw700 };
        
        ${({ theme }) => theme.media("xs", `
            font-size: 12px;
        `)}
        
        ${({ theme }) => theme.media("md", `
            font-size: 13px;
        `)}


        .text {
            span {
                display: block;
                white-space: nowrap;
                line-height: 1;
            }
        }
    }
`

export const Icon = styled.div `
    background-color: rgba(255,255,255,0.1);
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 14px;

    &::after {
        display: block;
        content: "";
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 100%;
        position: absolute;
        bottom: 0;
        top: 0;
        left: -5px;
        right: 0;
        margin: auto;
        background-color: rgba(255,255,255,0.05);
    }
`

export const Column = styled.div `

`;