import { SET_WEBSITE, SET_WEBISTE_PROPERTIES } from './../actionTypes';

export const setWebsite = website => ({
    type: SET_WEBSITE,
    payload: {
        website
    } 
})

export const setSiteProperties = site_properties => ({
    type: SET_WEBISTE_PROPERTIES,
    payload: {
        site_properties
    }
})