import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import LogoImage from "../assets/images/logo.png";

const WrapperLoader = styled.div`
    background-color: #f9f9f9;
    height: 100vh;
`;

const ContainerLoader = styled.div`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
        transform: rotate(90deg);
        width: 1.875rem;
        height: auto;
        -webkit-animation-name: spin;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: spin;
        -moz-animation-duration: 1000ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -ms-animation-name: spin;
        -ms-animation-duration: 1000ms;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;
        
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @-ms-keyframes spin {
        from { -ms-transform: rotate(0deg); }
        to { -ms-transform: rotate(360deg); }
    }

    @-moz-keyframes spin {
        from { -moz-transform: rotate(0deg); }
        to { -moz-transform: rotate(360deg); }
    }

    @-webkit-keyframes spin {
        from { -webkit-transform: rotate(0deg); }
        to { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
    
`;

const Loader = () => (
    <WrapperLoader>
        <ContainerLoader>
            <img src={LogoImage} alt='Scamb' />
        </ContainerLoader>
    </WrapperLoader>
);

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => {

    return (
        <Suspense fallback={<Loader />}>
            <Route {...rest} render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )} />
        </Suspense>
    )
}

export default LayoutRoute;