import { SET_WEBSITE, SET_WEBISTE_PROPERTIES } from '../actionTypes';

const initialState = { }

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_WEBSITE:
            const { website } = action.payload;
            return { ...state, website }
        case SET_WEBISTE_PROPERTIES:
            const { site_properties } = action.payload;
            return { ...state, site_properties }
        default:
            return state;
    }
}