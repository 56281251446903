import styled from 'styled-components';


export const WrappeHeader = styled.div `
    header {
        section {
            ._med-container {
                div {
                    .column-4 {
                        .column-item {
                            .scamb-avatar {
                                cursor: pointer;
                                margin: 0;
                            }

                            .dropdown {
                                display: none;
                            } 

                            &:hover {
                                .dropdown {
                                    display: block;
                                    position: absolute;
                                } 
                            }

                        }
                        
                        ${({theme}) => theme.media('xs', `
                            display: none !important;
                        `)}

                        ${({theme}) => theme.media('sm', `
                            display: flex !important;
                        `)}
                    } 
                }   
            }
        }
    }
`;