import Service from './Service';

export default class Cart extends Service {

  constructor() {
    super();
  }

  insertCart(carrinho, website) {
    return this.axiosInstance.post(`/cart${website ? '?website=' + website : ''}`, carrinho);
  }

  findCart(website) {
    return this.axiosInstance.get(`/cart${website ? '?website=' + website : ''}`);
  }

  deleteCart(itemASerRemovido, index, website) {
    if (itemASerRemovido) {
      return this.axiosInstance.delete(`/cart${website ? '?website=' + website : ''}`, { data: { itemASerRemovido, index } });
    }
    return this.axiosInstance.delete(`/cart${website ? '?website=' + website : ''}`);
  }

  changeStatusAdsOfCart(status, website) {
    return this.axiosInstance.post(`/cart/changeItensStatus${website ? '?website=' + website : ''}`, status);
  }

  getTimer(website) {
    return this.axiosInstance.get(`/cart/timer${website ? '?website=' + website : ''}`);
  }

  eraseCart(website) {
    return this.axiosInstance.delete(`/cart/erase${website ? '?website=' + website : ''}`);
  }

  calculateCartMissingValue() {
    return this.axiosInstance.get(`/cart/cartMissingValue`);
  }

  applyDescontWithUserPoints({ descontPointsApplied }) {
    return this.axiosInstance.put(`/cart/applydescontpoints`, { descontPointsApplied });
  }

  checkDescontWithPoints() {
    return this.axiosInstance.get(`/cart/checkDescontWithPoints`);
  }

}
