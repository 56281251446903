//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Button = styled.button((props) => {

    const { theme } = props;
    const { color, fontWeight } = Object.assign( {}, theme );

    return `
        border: 0;
        max-width: 62px;
        padding: 0.125rem .25rem;
        background-color: ${color.red};
        border: 0.0625rem solid ${color.red};
        border-radius: .25rem;
        line-height: 1.4;

        a {
            font-size: 9px;
            font-weight: ${fontWeight.fw700}; 
            color: ${color.white};
        }
        
        &:hover {
            a {
                color: ${color.white};
            }
        }
    `
})