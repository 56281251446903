
import React from 'react';
import {Redirect} from 'react-router-dom';

import LayoutRoute from './../utils/LayoutRoute';

import Cookie from './../utils/Cookie';

const PrivateRoute = ({ ...props }) => (
    Cookie.getCookie('token') !== null ?
        (<LayoutRoute {...props} />) : (<Redirect to={{ pathname: "/login" }} />)
);

export default PrivateRoute;