//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Menu = styled.button((props) => {

    const { theme } = props;
    const { color } = Object.assign( {}, theme );

    return `
        background-color: ${color.transparent};
        border: 0;
        padding: .5em;
    `
}) ;

export const MenuBars = styled.span((props) => {

    const { theme } = props;
    const { color } = Object.assign( {}, theme );

    return `
        display: block;
        height: 2px;
        width: 20px;
        background-color: ${color.blue};
        margin-bottom: 3px;
        border-radius: 12px;
        position: relative;
        padding-bottom: 2px;

        &:last-child {
            margin-bottom: 0;
        }
    `
})

export const WrapUser = styled.button`
    padding: 4px;
    border: 0;
    background-color: ${({theme}) => theme.color.white};

    a {
        display: block;

        svg {
            height: 20px;
            width: auto !important;

            path {
                fill: ${({theme}) => theme.color.black};
            }
        }
    }
`;

export const TextButtonUser = styled.div`
    font-weight: ${({theme}) => theme.fontWeight.fw700};
    color: ${({theme}) => theme.color.black};
    font-size: 0.5rem;
`;