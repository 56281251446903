import React, {lazy} from 'react';
import {Switch, Route} from 'react-router-dom';

import LayoutRoute from './../utils/LayoutRoute';
import PrivateRoute from './../utils/PrivateRoute';

import Layout from './Layout';

// import Home from './pages/Home';
import NotFound404 from './../common_pages/NotFound404';

const Buy = lazy(() => import('./pages/checkout'));
const MarcasHome = lazy(() => import('./pages/home_partners/index'));
const Home = lazy(() => import('./pages/home/index.jsx'));

const ShoppingRoutes = (props) => (
    <Switch>
        <LayoutRoute layout={Layout} exact path="/" component={Home} />
        <LayoutRoute layout={Layout} exact path="/comprar" component={Buy} />
        <LayoutRoute layout={Layout} exact path="/marcas" component={MarcasHome} />

        <Route path="*">
            <NotFound404 />
        </Route>        
    </Switch>
)
    

export default ShoppingRoutes;