//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//=====================================================================================
// #2 - Import * Utils
//=====================================================================================
import Cookie from "../../../utils/Cookie";

//=====================================================================================
// #2 - Import * Universal Cookie
//=====================================================================================
import Cookies from "universal-cookie";

//===============================================================================================================
// #1 - Import * UI Components
//===============================================================================================================
import { Container } from "../../design/layout/container";
import ExitIntent from "../../feedback/exit-intent";
import FixedMenu from "../../FixedMenu";

//===============================================================================================================
// #1 - Import * Styled Component
//===============================================================================================================
import { Main, Columns, Column as HColumn, ColumnItem as HColumnItem } from "./styles";

//=====================================================================================
// #8 - Import * Google Tag Manager
//=====================================================================================
import TagManager from 'react-gtm-module'
import config from "../../../configs/config.json";

//=====================================================================================
// #5 - Instantiate Services
//=====================================================================================
const cookies = new Cookies();

//===============================================================================================================
// #1 - Import * Styled Component
//===============================================================================================================
export const ColumnItem = ({ children }) => <HColumnItem className="column-item"> {children} </HColumnItem>
export const Column = ({ className, children }) => <HColumn className={className}> {children} </HColumn>

//===========================================================
// #10 - GTM Configs
//===========================================================
const tagManagerArgs = { gtmId: config.gtmID }

TagManager.initialize(tagManagerArgs)

function Header({ theme, children }) {

    // * Get Site Props
    const site = {
        scamb: "scamb",
        shopping: "shopping"
    }

    // * States
    const [popup, setPopup] = React.useState(false);

    // * Banner Cookies
    const handleCookie = () => {

        // * Cookie Expires
        var date = new Date();
        var minutes = 7200;
        date.setTime(date.getTime() + (minutes * 60 * 1000));

        cookies.set("_popup", "true", { path: "/", expires: date });

        // * Close Popup
        setPopup(false);
    }

    // * Handle Exit Intent
    const mouseEvent = (e) => {
        const shouldShowExitIntent =
            !e.toElement && !e.relatedTarget && e.clientY < 10;

        if (shouldShowExitIntent) {
            document.removeEventListener("mouseout", mouseEvent);

            // document.querySelector(".exit-intent-popup").classList.add("visible");

            // * Get JWT Token
            const token = Cookie.getCookie("token");

            // * Get Cookie Value
            const cookie_popup = cookies.get("_popup");

            if (token === null && cookie_popup === undefined) {
                setPopup(!popup);
            } else {
                setPopup(popup);
            }
        }
    };

    React.useEffect(() => {

        /*
        **********************************************
        * Handle Promotion Popup
        **********************************************
        */

        // * Trigger Mouseout Event in 10 seconds
        setTimeout(() => {
            document.addEventListener("mouseout", mouseEvent);
        }, 10000);


    }, [])

    return (
        <>

        {/* {popup && (
            <ExitIntent onClick={handleCookie} />
        )} */}

        <FixedMenu site={site[theme]} />

        <Main site={site[theme]}>
            <Container withContainer={true}>
                <Columns>
                    {children}
                </Columns>
            </Container>
        </Main>
        </>
    )

}

export default Header;