//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Cookies = styled.section`
    
  background-color: #14233E;
  padding: 0.9375rem 0;
  border-bottom: 0.0625rem solid rgba(255,255,255,0.4);

  ._med-container {
    ._cols {
      display: flex;
      align-items: center;

      @media (min-width: 240px) {
        flex-direction: column;
        justify-content: center;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      ._col {
        @media (min-width: 240px) {
          width: 100%;
          text-align: center;
        }

        @media (min-width: 768px) {
          width: auto;
          text-align: left;
        }

        p {
          color: #FFFFFF;
          font-size: 12px;
          line-height: 1.8;
        }

        button {

            @media (min-width: 240px) {
            max-width: 380px;
            margin-top: 10px;
          }

          @media (min-width: 768px) {
            max-width: 136px;
            margin-top: 0;
          }
        }

        &:first-child {
          padding-right: 15px;
        }
      }
    }
  }

`;