//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #1 - Import * Styled Component
//===============================================================================================================
import { MegaMenu } from "./styles";

export const WrapperMegaMenu = ({ children }) => <MegaMenu id="mega-menu">{children}</MegaMenu>;