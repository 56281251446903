//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const MegaMenu = styled.div((props) => {

    const { theme } = props;
    const { color, media } = Object.assign( {}, theme );

    return `
    background-color: ${color.white};
    border-top: 1px solid ${color.ice};
    border-bottom: 1px solid ${color.ice};
    position: relative;

    ${media("xs", `
        display: none;
    `)}

    ${media("lg", `
        display: block;
    `)}
`   
}) ; 