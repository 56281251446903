
import config from './../configs/config.json';


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();

    let server = config.nodejs.server;

    if(server.indexOf('scamb') === -1) {
        document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=localhost;path=/;";
    } else {
        document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=scamb.com.vc;path=/;";
    }
    
}
  
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }

    }

    return null;
}


const Cookie = { setCookie, getCookie };


export default Cookie;