//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//=====================================================================================
// #2 - Import * React Router Dom
//=====================================================================================
import { Link } from "react-router-dom";

//=====================================================================================
// #3 - Import * Styled Components
//=====================================================================================
import { ButtonDefault, ButtonLabel as StyledButtonLabel } from "./styles";

export const ButtonLabel = ({buttonColor, icon, text, onClick, style}) => {

	return (
		<StyledButtonLabel onClick={onClick} buttonColor={buttonColor} style={style}>
			<div className="icon">
				{icon}
			</div>
			<div className="text-label">
				{text}
			</div>
		</StyledButtonLabel>
	)
}

function Button({ buttonText, type, borderColor, buttonColor, textColor, buttonSize, buttonLink, maxWidth, iconColor, onClick, target, disabled }) {

    // <Button
    //     type="bordered"
    //     buttonSize="small"
    //     buttonColor="blue"
    //     textColor="red"
    //     buttonText="Hello"
    //     borderColor="red"
    //     buttonLink="/login"
    //     maxWidth={200}
    // />
    
    return (
        <>

            
            <ButtonDefault
                type={type}
                borderColor={borderColor}
                buttonColor={buttonColor}
                textColor={textColor}
                buttonSize={buttonSize}
                maxWidth={maxWidth}
                onClick={onClick}
                iconColor={iconColor}
                disabled={disabled || false}
            >

                {!target? 
                    <Link to={buttonLink ? buttonLink : "#!"}>
                        {buttonText}
                    </Link>
                    :
                    <a href={buttonLink ? buttonLink : "#!"} target={target}>{buttonText}</a>
                }
                

            </ButtonDefault>
        </>
    )
}

export default Button;