import React from 'react';
import { Link } from "react-router-dom";

import { Container, Text } from './styles';

export default function ToastInline({ link, children }) {

    return (
        <Container>
            <Link to={link ? link : "/resultados/?limit=8"}>
            <Text>
                {children}
            </Text>
            </Link>
        </Container>
    )
}