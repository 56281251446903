//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//=====================================================================================
// #1 - Import * React Router Dom
//=====================================================================================
import { Link } from "react-router-dom";

//=====================================================================================
// #3 - Import * UI Components
//=====================================================================================
import { Container } from "../../design/layout/container";
import Button from "../../design/form/button";

//=====================================================================================
// #2 - Import * Styled Components
//=====================================================================================
import { Cookies } from "./styles";

function CookieConsent({ handleCookie, text, linkPolicies, linkPoliciesText }) {

    return (
        <>
            <Cookies>
                <Container withContainer={true}>
                    <div className="_cols">
                        <div className="_col">
                            <p>
                                {text}
                                <Link to={`${linkPolicies ? linkPolicies : "#undefined-link"}`}> {linkPoliciesText} </Link>.
                            </p>
                        </div>
                        <div className="_col">

                            <Button
                                type="bordered"
                                buttonSize="small"
                                buttonColor="#c2403f"
                                textColor="#FFFFFF"
                                buttonText="Concordo"
                                borderColor="#C2403F"
                                buttonLink="#!!"
                                maxWidth={200}
                                onClick={handleCookie}
                            />

                        </div>
                    </div>
                </Container>
            </Cookies>
        </>
    )
}

export default CookieConsent;