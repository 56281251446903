//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

export default function Avatar({size, image}) {

    return (
        <button type="button" className={!size ? "scamb-avatar" : `scamb-avatar ${size}`}>
            <div className="image">
                <img src={image} alt="Avatar scamb" />
            </div>
        </button>
    )
}