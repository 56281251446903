//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//=====================================================================================
// #1 - Import * React Router Dom
//=====================================================================================
import { Link } from "react-router-dom";

//=====================================================================================
// #2 - Import * Styled Components
//=====================================================================================
import { Branding } from "./styles";

export const Logo = ({ link, image, alt }) => {

    return (
        <>
            <Branding>
                <Link to={link ? link : "#undefined-link"}>
                    <img src={image} alt={alt} />
                </Link>
            </Branding>
        </>
    )
}

export default Logo;