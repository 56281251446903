//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

export const ButtonLink = ({ link, text, color, bordered, onClick }) => {

    let button_color;

    switch(color) {
        case "red":
            button_color = "color-red"
            break;

        case "blue":
            button_color = "color-blue"
            break;

        default:
            button_color = "";
    }

    return (
        <button type="button" className={`btn-scamb-action ${bordered ? "bordered" : ""} ${color ? button_color : ""}`} onClick={onClick}>
            <Link to={link ? link : "#!"} >
                {text}
            </Link>
        </button>
    )
}

export const ButtonAnchor = ({ link, text, color, bordered, onClick }) => {

    let button_color;

    switch(color) {
        case "red":
            button_color = "color-red"
            break;

        case "blue":
            button_color = "color-blue"
            break;

        default:
            button_color = "";
    }

    return (
        <button type="button" className={`btn-scamb-action ${bordered ? "bordered" : ""} ${color ? button_color : ""}`} onClick={onClick}>
            <Link to={link ? link : "#!"} >
                {text}
            </Link>
        </button>
    )
}