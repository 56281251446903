//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

// ${props => props.theme.media("xs", ``)}

export const Popup = styled.div `

${props => props.theme.media("xs", `
    display: none !important;

`)}

${props => props.theme.media("sm", `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1328 !important;
    overflow: hidden;
    display: flex !important /*flex*/;
    align-items: flex-start;
    justify-content: center;    
`)}

._wrap-new-account-banner {
    
    position: relative;
    max-width: 800px;
    width: 100%;
    top: 0;
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
    
    ._close-modal {
        position: absolute;
        right: 4px;
        top: 6px;
        width: auto;
        
        ${props => props.theme.media("xs", `
            display: none;
        `)}
        
        ${props => props.theme.media("sm", `
            display: block;
        `)}
        
        svg {
            
            width: 16px;
            height: 16px;
            
            path {
                opacity: 0.6;
                fill: ${props => props.theme.color.black};
            }
            
        }
        
        &:hover {
           
            svg {
            
                path {
                    opacity: 1;
                }
            
            }
            
        }
    }
    
    ._wrap-new-account-banner--inside {

        background-color: ${props => props.theme.color.white};
        text-align: center;
        border-radius: 0.3125rem;
        max-height: 560px;
        
        .columns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .column {
                overflow: hidden;

                ._title-sub {
                    position: relative;
                    top: 15px;

                    .title,
                    .text {
                        font-weight: ${props => props.theme.fontWeight.fw700};
                        color:  ${props => props.theme.color.black};;
                    }
                    
                    .title {
                        font-size: 1.625rem;
                    }

                    .text {
                        font-size: 0.8125rem;
                        margin-top: 10px;
                    }
                }

               #subscribe-form {
                   border: 0;
                   min-height: 444px;
                   max-width: 370px;
                   width: 100%;
                   overflow: hidden !important;
               }

                &:nth-child(1) {
                    width: 400px;
                    min-width: 400px;
                    overflow: hidden;
                    border-top-left-radius: 0.3125rem;
                    border-bottom-left-radius: 0.3125rem;
                }

                &:nth-child(2) {
                    width: 100%;
                    padding: 0 30px;
                }
            }

            .column.image {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                height: 500px;
            }
        }
    }
    
}
`; 